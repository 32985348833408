<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar zona de entrega</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Department -->
        <vs-input @blur="delivery.department= trimString(delivery.department)" label="Departamento"
                  v-model="delivery.department"
                  class="w-full" name="department"
                  v-validate="'required|min:3|max:50'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('department')">{{ errors.first('department') }}</span>

        <div class="mt-5">
          <!-- Province -->
          <vs-input @blur="delivery.province= trimString(delivery.province)" label="Provincia"
                    v-model="delivery.province"
                    class="w-full" name="name"
                    v-validate="'required|min:3|max:50'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('province')">{{ errors.first('province') }}</span>
        </div>

        <div class="mt-5">
          <!-- District -->
          <vs-input @blur="delivery.district= trimString(delivery.district)" label="Distrito"
                    v-model="delivery.district"
                    class="w-full" name="name"
                    v-validate="'required|min:3|max:50'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('district')">{{ errors.first('district') }}</span>
        </div>

        <div class="mt-5">
          <!-- Price -->
          <vs-input label="Precio" v-model.number="delivery.price" class="mt-5 w-full" name="price"
                    v-validate="'required|min_value:0'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('price')">{{ errors.first('price') }}</span>
        </div>

        <!-- Observation -->
        <vs-input label="Observación" v-model.number="delivery.observation" class="mt-5 w-full"
                  name="observation" v-validate="'max:50'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('observation')">{{ errors.first('observation') }}</span>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Modificar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from "../../mixins/trimString"
import vSelect from 'vue-select'

const firebase = require("firebase")
// Required for side-effects
require("firebase/firestore")
let db = firebase.firestore()
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data() {
    return {
      delivery: {},
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      days: [
        {label: 'Lunes', value: 2},
        {label: 'Martes', value: 3},
        {label: 'Miercoles', value: 4},
        {label: 'Jueves', value: 5},
        {label: 'Viernes', value: 6},
        {label: 'Sabado', value: 7},
        {label: 'Domingo', value: 1},
      ]
    }
  },
  watch: {
    isSidebarActive() {
      this.delivery = {
        ...this.data
      }
      this.$validator.reset()
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid() {
      return !this.errors.any()
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData() {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true
          const obj = {
            ...this.delivery,
            uid: firebase.auth().currentUser.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          }
          // Update delivery
          db.collection('deliveries').doc(this.delivery.id).update({
            ...obj
          }).then(() => {
            this.$store.dispatch('deliveries/updateDelivery', {delivery: obj})
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Zona de entrega',
              text: 'Zona de entrega modificada correctamente.'
            })
            this.$emit('closeSidebar')
          }).catch(() => {
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
